* {
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
}

.Drawer {
  position: relative;
  overflow: auto;
  color: #A6A6A6;
  text-align: center;
  padding-top: 42px;
  -webkit-overflow-scrolling: touch;
}

.SideBar {
  background: #FFF;
}

.info {
  height: calc(100vh - 45px);
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
  

  span {
    color: black;
    font-size: 18px;
    margin-bottom: 25px;
    text-align: left;
    width: 100%;
    padding: 0 10px;;
  }

  li {
    text-align: left;
  }

  a {
    font-size: 18px;
    cursor: pointer;
  }

  .title {
    font-size: 36px;
    text-align: center;
  }

}