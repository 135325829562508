.RootContainer {
  display: flex;
  justify-content: center;
}

.Informativo {
  padding: 12px;
  max-width: 800px;
  p {
    color: black;
    text-align: left;
    font-size: 21px;
    letter-spacing: -0.003em;
    line-height: 32px;
  }
  h2 {
    text-align: left;
    font-weight: bolder;
  }
}

.GridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.Partituras {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  margin-bottom: 1em;
}